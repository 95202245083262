<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) Evaluate the following equation using the correct number of significant figures.
        <stemble-latex
          content="$\displaystyle{\frac{8.314 \cdot (23.15-22.75)}{1.23 \cdot 9.574}}$"
        />
      </p>

      <calculation-input
        v-model="inputs.mathResponse"
        prepend-text="$\text{Value: }$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-3">
        b) Explain how you came to your final result in the previous part of the question.
      </p>

      <p class="mb-2">
        <s-textarea v-model="inputs.textResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemCPP1210PQ1_Q1',
  components: {CalculationInput, STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        mathResponse: null,
        textResponse: null,
      },
    };
  },
};
</script>
